import React from 'react';
import { NavBar, Footer } from '../components';
import { FaInstagram, FaYoutube, FaSoundcloud } from 'react-icons/fa';

function Contact() {
  return (
    <div style={{ minHeight: '100vh', width: '100%', backgroundColor: '#111', fontFamily: 'Quicksand' }}>
      <NavBar />
      <section style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        color: '#EEE', 
        textAlign: 'center', 
        minHeight:'100vh', 
        padding: '0 10%'
      }}>
        <h1 style={{ marginBottom: '20px', fontSize: '2.5em' }}>Contact</h1>
        <p style={{ marginBottom: '30px', fontSize: '1.2em', lineHeight: '1.5' }}>
          If you have any questions or would like to reach out, please use the information below:
        </p>

        <div style={{ marginBottom: '40px', fontSize: '1.1em' }}>
          <div><strong>Email:</strong> soheil0804@icloud.com</div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center', gap: '25px' }}>
          <a 
            href="https://instagram.com/soheil.ofc" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#EEE', transition: 'color 0.3s' }}
            onMouseEnter={(e) => e.currentTarget.style.color = '#E1306C'}
            onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          >
            <FaInstagram size={40} />
          </a>
          <a 
            href="https://m.youtube.com/@Radian_music" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#EEE', transition: 'color 0.3s' }}
            onMouseEnter={(e) => e.currentTarget.style.color = '#FF0000'}
            onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          >
            <FaYoutube size={40} />
          </a>
          <a 
            href="https://on.soundcloud.com/rRkoBBXy9gKuM1bQ6" 
            target="_blank" 
            rel="noopener noreferrer" 
            style={{ color: '#EEE', transition: 'color 0.3s' }}
            onMouseEnter={(e) => e.currentTarget.style.color = '#FF5500'}
            onMouseLeave={(e) => e.currentTarget.style.color = '#EEE'}
          >
            <FaSoundcloud size={40} />
          </a>
        </div>
      </section>  
      <Footer/>
    </div>
  );
}

export default Contact;
