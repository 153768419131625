import React, { useState } from 'react';
import {NavBar, Footer} from '../components';
import { FaFlagUsa, FaFlagDe } from 'react-icons/fa';
//https://www.stephanbodzin.de
function Home() {




  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 800;
  const [language, setLanguage] = useState(null);



  return (
<div style={{ minHeight: '100vh', width: '100%', backgroundColor: '#111', fontFamily: 'Quicksand' }}>
  <NavBar />
  <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white', backgroundColor: 'rgba(1,1,1,1)', textAlign: 'center' }}>
            <img 
                style={{
                    height: '120vh',
                    width: '100vw',
                    objectFit: 'cover',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)'
                }} 
                src="https://firebasestorage.googleapis.com/v0/b/hofladen-kabe.appspot.com/o/soheil%2FIMG_8143.JPG?alt=media&token=6f68863b-d32a-4ca9-a4ea-a7d0bb7d92f2&_gl=1*6rkb70*_ga*MTUwODkzNDAyOS4xNjg5ODU3MzI2*_ga_CW55HF8NVT*MTY5NzMxMTk0OS4xNDIuMS4xNjk3MzEyODUwLjYwLjAuMA.."
                alt="Foto" 
            />
            <div style={{height:80}}></div>
            <div style={{ 
      backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/hofladen-kabe.appspot.com/o/soheil%2FIMG_9323.jpg?alt=media&token=143c4b50-6247-498e-9f8a-1e58690952fb&_gl=1*1jme8tf*_ga*MTUwODkzNDAyOS4xNjg5ODU3MzI2*_ga_CW55HF8NVT*MTY5NzMyMzAzMC4xNDMuMS4xNjk3MzIzMDY0LjI2LjAuMA..")` 
    }}>
            <h1 style={{ fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: 60, margin: 0, padding: '20px 0' }}>
                RADIAN
            </h1>
            <p style={{ padding: isMobile ? '0 40px':'0 120px' }}>
            From the vibrant heart of Marbella, where the Atlantic meets Spain's golden shores, rises a musical legend - DJ Radian. In the real world, he's known as Soheil Alimoradian, with Moroccan and Persian blood running through his veins.
            </p>
            <p style={{ padding: isMobile ? '0 40px':'0 120px' }}>
            This deep cultural mix is felt in every beat he drops. Radian masterfully blends lively African rhythms with the enchanting allure of the Orient. The result? Electrifying house music that sets dance floors on fire.
            </p>
            <p style={{ padding: isMobile ? '0 40px':'0 120px' }}>
            Even as a kid, Radian was enchanted by melodies. His fingers danced on the violin, tapped the percussions, and glided on the piano keys. Music was his destiny. But it was Marbella, with its glitzy nightlife and club scene, that opened his eyes to the mesmerizing world of DJing and electronic tunes. Here, he found his true calling.
            </p>
            <p style={{ padding: isMobile ? '0 40px':'0 120px' }}>
            Now, in cities like Baden Baden and Karlsruhe, his name is whispered with respect. Every DJ Radian performance isn't just a show; it's an experience. A journey through cultures, eras, and feelings. He's not just a DJ; he's a storyteller, a sound magician.
            </p>
            <p style={{ padding: isMobile ? '0 40px':'0 120px' }}>
            So, as the sun sets and darkness blankets the city, listen closely. You might catch the irresistible beat of DJ Radian, bringing the night to life. Get ready to be whisked away by him into a world where boundaries blur, and only the music matters.
            </p>
            <div style={{height: 100}}></div>

            </div>

        </section>  
      <Footer/>
    </div>
  );
}

export default Home;