import React, { useState } from 'react';
import { NavBar, Footer } from '../components';
import backgroundVideo from './IMG_9294.mp4';

function Music() {
  const dates = [
    { date: '27.Okt', location: "Max's", city: 'Baden-Baden, Germany' },
    { date: '3.Nov', location: "Salon Privé at Borchardt", city: 'Berlin, Germany' },
  ]

  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 800;

  return (
    <div style={{ minHeight: '100vh', width: '100%', backgroundColor: '#111', fontFamily: 'Quicksand' }}>

      <div style={{ zIndex: 2, position: 'relative' }}>
        <NavBar />
      </div>

      <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: 'white', textAlign: 'center', minHeight: '100vh', position: 'relative' }}>
        <video autoPlay loop muted playsInline style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: 0 }}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>
        <div style={{ position: 'relative', zIndex: 1 }}>
          <img 
            style={{ height: '20vw', width: '20vw', objectFit: 'cover', boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)' }}
            src="https://firebasestorage.googleapis.com/v0/b/hofladen-kabe.appspot.com/o/soheil%2FIMG_1036.PNG?alt=media&token=c07b40cf-1f4f-4b08-be78-0a1574cecdd8&_gl=1*1rb4j3i*_ga*MTUwODkzNDAyOS4xNjg5ODU7MzI2*_ga_CW55HF8NVT*MTY5NzMyMzAzMC4xNDMuMS4xNjk3MzI3MDIyLjU1LjAuMA.." alt="Foto" />
          <p style={{ fontSize: 40, backgroundColor: 'rgba(0,0,0,0.1)', paddingLeft: 5, paddingRight: 5, color: 'white', fontFamily: 'Montserrat' }}>COMING SOON...</p>
          
        </div>
      </section>

      <Footer />

    </div>
  );
}

export default Music;
