import React, { useState } from 'react';
import { Oval } from 'react-loader-spinner'
import { FaInstagram, FaSoundcloud, FaYoutube } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';


function Loading() {

  return (

<div
    style={{
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "#001F3F",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxSizing: "border-box",
        fontFamily: 'Quicksand',
        textAlign: 'center',
    }}
>
    <Oval
        height="100"
        width="100"
        radius="9"
        color="white"
    />
    <h2 style={{ color: 'white' }}>Danke, für Ihre Geduld</h2>
</div>


)
}




function Footer() {
  return (
    <footer style={{ backgroundColor: '#222', padding: '20px 50px', textAlign: 'center', color: 'white' }}>
      <div style={{ marginBottom: '20px' }}>
        <a href="https://instagram.com/soheil.ofc" style={{ margin: '0 10px', color: 'white', textDecoration: 'none' }}>
          <FaInstagram size={30} />
        </a>
        <a href="https://on.soundcloud.com/rRkoBBXy9gKuM1bQ6" style={{ margin: '0 10px', color: 'white', textDecoration: 'none' }}>
          <FaSoundcloud size={30} />
        </a>
        <a href="https://m.youtube.com/@Radian_music" style={{ margin: '0 10px', color: 'white', textDecoration: 'none' }}>
          <FaYoutube size={30} />
        </a>
      </div>
      <div>
        <strong>Impressum</strong><br />
        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br />
        Soheil Alimoradian<br />
        Seboldstraße 1<br />
        76227 Karlsruhe<br />
        E-Mail: radianmusic@alimoradian.com<br />
      </div>
    </footer>
  );
}

function NavBar() {
  const [showMenu, setShowMenu] = useState(false);



  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 800;

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div style={{ 
      position: 'fixed', 
      top: 0, 
      width: '100%', 
      backgroundColor: 'rgba(17,17,17,0.9)', 
      padding: '10px', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
      {/* Logo */}
      <div>
        <a href="/">
          <img src="https://firebasestorage.googleapis.com/v0/b/hofladen-kabe.appspot.com/o/soheil%2Flogo-no-background.png?alt=media&token=58534958-3aec-44a9-920e-41d19785db0d&_gl=1*x9j2fr*_ga*MTUwODkzNDAyOS4xNjg5ODU3MzI2*_ga_CW55HF8NVT*MTY5NzI1MTg0NC4xNDAuMS4xNjk3MjUyOTk0LjUyLjAuMA.." alt="Logo" style={{ height: '50px', marginLeft: 10 }} />
        </a>
      </div>
      {!isMobile && (
        <div style={{ 
          cursor: 'pointer', 
          padding: '10px'
        }}>
          <a href="/" style={{ 
            margin: '5px 0', 
            color: '#fff', 
            textDecoration: 'none', 
            padding: '5px', 
            borderRadius: '4px', 
            transition: 'background-color 0.3s',
            marginRight: '11vw',
            fontFamily:'Montserrat',
            fontWeight: 'bold',
            fontSize: 20
          }} onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'} 
            onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}>HOME</a>

            <a href="/dates" style={{ 
            margin: '5px 0', 
            color: '#fff', 
            textDecoration: 'none', 
            padding: '5px', 
            borderRadius: '4px', 
            transition: 'background-color 0.3s',
            marginRight: '11vw',
            fontFamily:'Montserrat',
            fontWeight: 'bold',
            fontSize: 20
          }} onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'} 
            onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}>DATES</a>

            <a href="/music" style={{ 
            margin: '5px 0', 
            color: '#fff', 
            textDecoration: 'none', 
            padding: '5px', 
            borderRadius: '4px', 
            transition: 'background-color 0.3s',
            marginRight: '11vw',
            fontFamily:'Montserrat',
            fontWeight: 'bold',
            fontSize: 20
          }} onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'} 
            onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}>MUSIC</a>

<a href="/contact" style={{ 
            margin: '5px 0', 
            color: '#fff', 
            textDecoration: 'none', 
            padding: '5px', 
            borderRadius: '4px', 
            transition: 'background-color 0.3s',
            fontFamily:'Montserrat',
            fontWeight: 'bold',
            fontSize: 20,
            marginRight:'40px'
          }} onMouseOver={(e) => e.target.style.backgroundColor = 'rgba(255, 255, 255, 0.2)'} 
            onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}>CONTACT</a>
        </div>
      )}
      {isMobile && (
        <div onClick={toggleMenu} style={{ 
          cursor: 'pointer', 
          padding: '10px'
        }}>
                {isMobile &&(
        <div onClick={toggleMenu} style={{ 
          cursor: 'pointer', 
          padding: '10px', 
          position: 'fixed', 
          top: '10px', 
          right: '10px' 
        }}>
          <div style={{ 
            width: '30px', 
            height: '4px', 
            backgroundColor: '#efefef', 
            margin: '4px 0', 
            borderRadius: '2px' 
          }}></div>
          <div style={{ 
            width: '30px', 
            height: '4px', 
            backgroundColor: '#efefef', 
            margin: '4px 0', 
            borderRadius: '2px' 
          }}></div>
          <div style={{ 
            width: '30px', 
            height: '4px', 
            backgroundColor: '#efefef', 
            margin: '4px 0', 
            borderRadius: '2px' 
          }}></div>
        </div>
      )}

        </div>
      )}
      <div style={{
  transform: showMenu ? 'translateX(0)' : 'translateX(-100%)',
  transition: 'transform 0.3s ease-in-out',
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  backgroundColor: '#020202',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center', // Vertikal zentrieren
  boxShadow: '2px 0px 5px rgba(0,0,0,0.5)'
}}>
  <div onClick={toggleMenu} style={{ 
    cursor: 'pointer', 
    alignSelf: 'flex-start',
    marginBottom: '10px', 
    padding: '10px', 
    borderRadius: '4px', 
    transition: 'background-color 0.3s',
    display: 'flex',
    justifyContent: 'space-between', 
    alignItems: 'center',
    width: 40
  }} 
  >
    <FiX size={30} style={{margin:0, color:'white'}} />
  </div>

  <div style={{ 
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Vertikal zentrieren
    alignItems: 'center', // Horizontal zentrieren
  }}>
    {/* Die folgenden <a> Elemente sind für den Zweck der Demonstration identisch. In einem echten Szenario sollten sie unterschiedliche Inhalte und Links haben. */}
    {[{name: 'HOME', ref:'/'}, {name: 'DATES', ref:'/dates'}, {name: 'MUSIC', ref:'/music'}, {name: 'CONTACT', ref:'/contact'}].map((text, index) => (
      <a 
        key={index}
        href={text.ref}
        style={{ 
          color: '#fff', 
          textDecoration: 'none', 
          padding: '5px', 
          borderRadius: '4px', 
          transition: 'background-color 0.3s',
          fontFamily:'Montserrat',
          fontWeight:'bold',
          fontSize: 30,
          textAlign:'center',
          marginBottom: index !== 3 ? '20px' : '0px', // Verhindert marginBottom für das letzte Element,
          cursor:'pointer'
        }} 
      >
        {text.name}
      </a>
    ))}
  </div>
</div>

    </div>
  );
}

export { NavBar, Footer, Loading };
