import React, { useState } from 'react';
import {NavBar, Footer} from '../components';
//https://www.stephanbodzin.de
function Dates() {

/* 
27.Okt | Max's | Baden-Baden, Germany
3.Nov | Salon Privé at Borchardt | Berlin, Germany
 */


const dates = [
  {date: '27.Okt', location: "Max's", city: 'Baden-Baden, Germany'},
  {date: '3.Nov', location: "Salon Privé at Borchardt", city: 'Berlin, Germany'},
]


  const windowWidth = window.innerWidth;
  const isMobile = windowWidth <= 800;



  const bgImageUrl = "https://firebasestorage.googleapis.com/v0/b/hofladen-kabe.appspot.com/o/Unbenanntes_Projekt.jpg?alt=media&token=5294da7f-3cd9-4f31-9e53-9836cff43657&_gl=1*1kbvd7n*_ga*MjExOTM5MjQzNi4xNjk3NDkzMDY2*_ga_CW55HF8NVT*MTY5NzkyOTgwOS4zLjEuMTY5NzkyOTg1NC4xNS4wLjA.";

return (
  <div style={{
    minHeight: '100vh', 
    width: '100%', 
    fontFamily: 'Quicksand', 
    backgroundImage: `url(${bgImageUrl})`, 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed'
  }}>
    <NavBar />
    <section style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      color: 'white', 
      backgroundColor: 'rgba(1,1,1,0.5)', 
      textAlign: 'center', 
      minHeight:'100vh' 
    }}>
      <div style={{marginTop: 100}}></div>
      {dates.map((date, index) => (
        <p style={{fontSize: 25, fontWeight:'bolder'}}>{date.date} | {date.location} | {date.city}</p>
      ))}
    </section>  
    <Footer/>
  </div>
);


}

export default Dates;